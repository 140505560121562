import { getRequest } from './index'

const CSRF_ENDPOINT_URL = '/libs/granite/csrf/token.json'
const CSRF_ENDPOINT_WORST_DELAY_SECONDS = 30
const CSRF_REQUEST_HEADER = 'CSRF-Token'

let currentIntervalId: number

export function isCsrfProtectionEnabled() {
  return document.body.dataset?.csrfProtectionEnabled !== undefined
}

export function initCsrfTokenRefresher(headers: Headers) {
  const csrfTokenTimeoutSeconds = Number(document.body.dataset?.csrfTokenTimeoutSeconds)
  if (!csrfTokenTimeoutSeconds) {
    console.error('Missing a valid value for <body> attribute data-csrf-token-timeout-seconds')
    return
  }
  if (currentIntervalId) {
    clearInterval(currentIntervalId)
  }
  refreshCsrfToken(headers)
  currentIntervalId = window.setInterval(() => refreshCsrfToken(headers), (csrfTokenTimeoutSeconds - CSRF_ENDPOINT_WORST_DELAY_SECONDS) * 1000)
}

function refreshCsrfToken(headers: Headers) {
  getRequest(CSRF_ENDPOINT_URL)
    .then(response => response.json())
    .then(data => {
      const lastSavedToken = getHeaderValue(CSRF_REQUEST_HEADER, headers)
      headers.set(CSRF_REQUEST_HEADER, data.token || lastSavedToken)
    })
    .catch(error => console.error(error))
}

function getHeaderValue(headerKey: string, headers: Headers) {
  return Array.from(headers.entries())?.find(([key, _value]: [string, string]) => key === headerKey.toLocaleLowerCase())?.[1]
}
