import './Comment.scss'

import { ActionsVariants, CommentActions, CommentContext, CommentReplies, CommentType } from '@react/Comments'
import { cls } from '@react/utils/classname'
import { formatDate } from '@site/js/utils/formatDate'
import { useContext, useState } from 'react'

interface CommentProps {
  comment: CommentType
}
export function Comment({ comment }: CommentProps) {
  const { numOfReplies, content, authorName } = comment
  const [activeAction, setActiveAction] = useState<null | ActionsVariants>(null)
  const { translations } = useContext(CommentContext)
  return (
    <div className={cls({ 'cmp-comment': true, 'cmp-comment--removing': activeAction === ActionsVariants.DELETE_COMMENT })} data-testid="comment">
      <div className="cmp-comment__header">
        <h5 className="cmp-comment__author">{authorName}</h5>
        <span className="cmp-comment__date">{formatDate(comment.date, translations.temporal)}</span>
      </div>
      {activeAction !== ActionsVariants.EDIT && <p className="cmp-comment__content" dangerouslySetInnerHTML={{ __html: content }}></p>}
      <div className="cmp-comment__controls">
        <CommentActions comment={comment} sendActiveAction={setActiveAction} />
      </div>
      {numOfReplies > 0 && <CommentReplies comment={comment} />}
    </div>
  )
}
