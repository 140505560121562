import { zoom } from '@site/js/utils/zoom'
export const ZOOM_REGULAR_ONLY = 'regularOnly'
export const ZOOM_FULLSCREEN_ONLY = 'fullScreenOnly'
const ACTIVE_IMAGE_CAROUSEL_ID = 'data-active-image-carousel-id'
const ZOOM_ENABLE_ATTRIBUTE = 'data-zoom-enable'
const FULLSCREEN_CLASS = 'cmp-image-carousel__fullscreen'
const PRODUCT_IMAGE_SELECTOR = '.cmp-image--product-image img'
export function shouldClearZoom(zoomEnable: string, fullScreen: boolean): boolean {
  return (zoomEnable === ZOOM_REGULAR_ONLY && fullScreen) || (zoomEnable === ZOOM_FULLSCREEN_ONLY && !fullScreen)
}

export function setZoomCursor(element: HTMLElement, isZooming: boolean) {
  element.classList.remove('img', 'cursor-default')
  if (isZooming) {
    element.classList.add('img')
  } else {
    element.classList.add('cursor-default')
  }
}
export function applyZoomEffectBasedOnState(element: HTMLElement) {
  const activeCarouselId = document.body.getAttribute(ACTIVE_IMAGE_CAROUSEL_ID)

  if (element.id === activeCarouselId) {
    const zoomEnable = element.getAttribute(ZOOM_ENABLE_ATTRIBUTE)
    if (zoomEnable === ZOOM_FULLSCREEN_ONLY && element.classList.contains(FULLSCREEN_CLASS)) {
      registerAndApplyZoomForImages(element)
    } else if (zoomEnable === ZOOM_REGULAR_ONLY && !element.classList.contains(FULLSCREEN_CLASS)) {
      registerAndApplyZoomForImages(element)
    } else if (zoomEnable === 'regularAndFullScreen') {
      registerAndApplyZoomForImages(element)
    } else {
      clearZoomForImages(element)
    }
  }
}

export function registerAndApplyZoomForImages(element: HTMLElement) {
  const zoomEnable = element.getAttribute(ZOOM_ENABLE_ATTRIBUTE)
  const isFullScreen = element.classList.contains(FULLSCREEN_CLASS)
  const isSingleImageMode = element.querySelector(PRODUCT_IMAGE_SELECTOR) !== null

  const getZoomFactor = (zoomEnable: string, isFullScreen: boolean): number => {
    if (zoomEnable === ZOOM_REGULAR_ONLY && !isFullScreen) {
      return 2
    }
    if (zoomEnable === ZOOM_FULLSCREEN_ONLY && isFullScreen) {
      return 2
    }
    if (zoomEnable === 'regularAndFullScreen') {
      return 2
    }
    return 1
  }

  const applyZoomToSingleImage = (imgElement: HTMLElement, zoomFactor: number) => {
    if (imgElement) {
      zoom(imgElement, zoomFactor)
      if (zoomEnable === ZOOM_FULLSCREEN_ONLY) {
        setZoomCursor(imgElement, true)
      } else {
      setZoomCursor(imgElement, zoomEnable !== ZOOM_FULLSCREEN_ONLY)
      }
    }
  }

  const applyZoomToCarouselImages = (carouselImages: NodeList, zoomFactor: number) => {
    carouselImages.forEach((image: HTMLElement) => {
      const img = image.querySelector('img') as HTMLElement
      if (img) {
        zoom(img, zoomFactor)
        if (zoomEnable === ZOOM_FULLSCREEN_ONLY) {
          setZoomCursor(img, true)
        } else {
        setZoomCursor(img, zoomEnable !== ZOOM_FULLSCREEN_ONLY)
        }
      }
    })
  }

  const zoomFactor = getZoomFactor(zoomEnable || '', isFullScreen)

  if (isSingleImageMode) {
    const imgElement = element.querySelector(PRODUCT_IMAGE_SELECTOR) as HTMLElement
    applyZoomToSingleImage(imgElement, zoomFactor)
  } else {
    const carouselImages = element.querySelectorAll('.cmp-image-carousel__image')
    applyZoomToCarouselImages(carouselImages, zoomFactor)
  }
}

export function clearZoomForImages(element: HTMLElement) {
  const carouselImages = element.querySelectorAll('.cmp-image-carousel__image')

  if (carouselImages && carouselImages.length > 0) {
    carouselImages.forEach((carouselImage: HTMLElement) => {
      const imgElement = carouselImage.querySelector('img')
      if (imgElement) {
        applyZoom(imgElement, 1)
        setZoomCursor(imgElement, false)
      }
    })
  } else {
    const imgElement = element.querySelector(PRODUCT_IMAGE_SELECTOR) as HTMLElement
    if (imgElement) {
      applyZoom(imgElement, 1)
      setZoomCursor(imgElement, false)
    }
  }
}
export function applyZoom(imgElement: HTMLElement, zoomFactor: number) {
  zoom(imgElement, zoomFactor)
}


document.addEventListener('fullscreenchange', function () {
  const elements = document.querySelectorAll(
    '.cmp-image-carousel,.cmp-image--product-image ,.cmp-image--product-image--normal,.cmp-pagestage-product__container-image-wrapper',
  )
  elements.forEach(element => {
    const zoomEnable = element.getAttribute('data-zoom-enable')
    const isFullScreen = element.classList.contains('cmp-image-carousel__fullscreen') || element.classList.contains('cmp-image--fullscreen')
    if (zoomEnable === 'regularOnly' && !isFullScreen) {
      registerAndApplyZoomForImages(element as HTMLElement)
    }
  })
})