import { getOffsetHandlers } from '@mocks/utils'

import allNews from '../json/news/allNews.json'
import allNewsOffset9 from '../json/news/allNews-9.json'
import allNewsOffset18 from '../json/news/allNews-18.json'

const news = [
  { key: '0', value: allNews },
  { key: '9', value: allNewsOffset9 },
  { key: '18', value: allNewsOffset18 },
]

const newsHandlers = getOffsetHandlers('all.newslist.listsearchresults.json', news)

export default newsHandlers
