import { http, HttpResponse } from 'msw'

import pastEventsMock from './json//events/pastEventsMock.json'
import assetSearchMock from './json/assetSearchMock.json'
import videoGetCommentsMock from './json/comments/video.getcomments.json'
import videoGetPageLikesMock from './json/comments/video.getpagelikes.json'
import videoCommentRepliesMock from './json/comments/video.getreplies.json'
import customerSuccessStoriesEventsMock from './json/customer-success-stories/customerSuccessStoriesEventsMock.json'
import customerSuccessStoriesEventsMockWithCountry from './json/customer-success-stories/customerSuccessStoriesEventsMockWithCountry.json'
import customerSuccessStoriesEventsMockWithMarkets from './json/customer-success-stories/customerSuccessStoriesEventsMockWithMarkets.json'
import customerSuccessStoriesEventsMockWithOffering from './json/customer-success-stories/customerSuccessStoriesEventsMockWithOffering.json'
import customerSuccessStoriesEventsMockWithSpecificProduct from './json/customer-success-stories/customerSuccessStoriesEventsMockWithSpecificProduct.json'
import customerSuccessStoriesTableMock from './json/customer-success-stories/customerSuccessStoriesTableMock.json'
import cyberSecurityMock from './json/cybersecurityMock.json'
import carouselEventsMock from './json/events/carouselEventsMock.json'
import eventsMock from './json/events/eventsMock.json'
import factEventsMock from './json/events/factEventsMock.json'
import multiMonthEventsMock from './json/events/multiMonthEventsMock.json'
import newsEventsMock from './json/events/newsEventsMock.json'
import onDemandEventsMock from './json/events/onDemandEventsMock.json'
import scheduledAndPastEventsMock from './json/events/scheduledAndPastEventsMock.json'
import simpleEventsMock from './json/events/simpleEventsMock.json'
import sortableEventsMock from './json/events/sortableEventsMock.json'
import unscheduledEventsMock from './json/events/unscheduledEventsMock.json'
import upcomingEventsMock from './json/events/upcomingEventsMock.json'
import allNewsWithFilterbar from './json/news/allNewsWithFilterbar.json'
import allNewsWithNews from './json/news/allNewsWithNews.json'
import allNewsWithPublicationDate from './json/news/allNewsWithPublicationDate.json'
import productsMock from './json/productsMock.json'
import relatedJobsMock from './json/relatedJobsMock.json'
import searchResultsTab0 from './json/search-result/search-result-tab-0.model.json'
import searchResultsTab1 from './json/search-result/search-result-tab-1.model.json'
import searchResultsTab2 from './json/search-result/search-result-tab-2.model.json'
import searchResultsTab3 from './json/search-result/search-result-tab-3.model.json'
import searchAutoSuggestion from './json/searchAutoSuggestion.json'
import relatedVideosMock from './json/video/relatedvideosMock.json'
import videolistMock from './json/video/videolistMock.json'
import videoTeaserListMock from './json/video/videoteaserlistMock.json'
import newsHandlers from './offsetHandlers/allnews'
import jobsHandlers from './offsetHandlers/jobs'

const handlerMapping = {
  'autocomplete-suggestions.json': searchAutoSuggestion,
  'search-result-tab-0.model.json': searchResultsTab0,
  'search-result-tab-1.model.json': searchResultsTab1,
  'search-result-tab-2.model.json': searchResultsTab2,
  'search-result-tab-3.model.json': searchResultsTab3,
  'eventlist.listsearchresults.json': eventsMock,
  'carousel.listsearchresults.json': carouselEventsMock,
  'unscheduledeventlist.listsearchresults.json': unscheduledEventsMock,
  'news.listsearchresults.json': newsEventsMock,
  'all.newslist.listsearchresults.with.filterbar.json': allNewsWithFilterbar,
  'all.newslist.listsearchresults.with.news.json': allNewsWithNews,
  'all.newslist.listsearchresults.with.publication.date.json': allNewsWithPublicationDate,
  'response.customerstories.listsearchresults.request.json': customerSuccessStoriesEventsMock,
  'response.customerstories.country.listsearchresults.request.json': customerSuccessStoriesEventsMockWithCountry,
  'response.customerstories.markets.listsearchresults.request.json': customerSuccessStoriesEventsMockWithMarkets,
  'response.customerstories.offering.listsearchresults.request.json': customerSuccessStoriesEventsMockWithOffering,
  'referencelist-table.listsearchresults.json': customerSuccessStoriesTableMock,
  'response.customerstories.specific.product.listsearchresults.request.json': customerSuccessStoriesEventsMockWithSpecificProduct,
  'simple.listsearchresults.json': simpleEventsMock,
  'relatedjoblist.listsearchresults.json': relatedJobsMock,
  'eventlist.onDemandJson.json': onDemandEventsMock,
  'eventlist.scheduledAndPastEvents.json': scheduledAndPastEventsMock,
  'eventlist.factEvents.json': factEventsMock,
  'rtuexternallinkselec.listsearchresults.json': sortableEventsMock,
  'productlist.listsearchresults.json': productsMock,
  'cybersecurityMock.listsearchresults.json': cyberSecurityMock,
  'multiMonthEvents.listsearchresults.json': multiMonthEventsMock,
  'video.getcomments.json': videoGetCommentsMock,
  'video.getpagelikes.json': videoGetPageLikesMock,
  'video.getreplies.json': videoCommentRepliesMock,
  'relatedvideos.listsearchresults.json': relatedVideosMock,
  'videolist.listsearchresults.json': videolistMock,
  'videoteaserlist.listsearchresults.json': videoTeaserListMock,
  'asset-search.listsearchresults.json': assetSearchMock,
  'upcomingevents.listsearchresults.json': upcomingEventsMock,
  'pastevents.listsearchresults.json': pastEventsMock,
}

// Note: json is an endpoint set on the "data-url" attribute of <filterable-list>

const getHandlers = Object.keys(handlerMapping).map(mapKey => http.get(mapKey, () => HttpResponse.json(handlerMapping[mapKey], { status: 200 })))
const offsetHandlers = [jobsHandlers, newsHandlers]

export const handlers = [...getHandlers, ...offsetHandlers]
