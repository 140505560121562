import { getOffsetHandlers } from '@mocks/utils'

import jobsMocks from '../json/jobs/jobsMock.json'
import jobMocksOffset20 from '../json/jobs/jobsMock-20.json'
import jobMocksOffset40 from '../json/jobs/jobsMock-40.json'

const jobs = [
  { key: '0', value: jobsMocks },
  { key: '20', value: jobMocksOffset20 },
  { key: '40', value: jobMocksOffset40 },
]

const jobsHandlers = getOffsetHandlers('joblist.listsearchresults.json', jobs)

export default jobsHandlers
