import { http, HttpResponse } from 'msw'

type mocksArrayType = {
  key: string
  value: unknown
}[]

export const getOffsetHandlers = (url: string, mocks: mocksArrayType) =>
  http.get(url, ({ request }) => {
    // Construct a URL instance out of the intercepted request.
    const url = new URL(request.url)
    const offset = url.searchParams.get('offset') || '0'

    // Return existing mock otherwise return random mock from the MockArray
    return HttpResponse.json(mocks.find(mock => mock.key === offset)?.value || mocks[Math.floor(Math.random() * mocks.length)].value, { status: 200 })
  })
