import { trackEvent } from '@site/js/utils/tracking'
export function handleTracking({ title = '', interactionType }: { title?: string; interactionType: string }) {
  trackEvent({
    event: 'interaction',
    eventSource: 'aem',
    action: 'click',
    componentName: 'imageCarousel',
    interactionType,
    title,
  })
}
