import './CommentActions.scss'

import { ActionsVariants, CommentContext, CommentDialog, CommentType, useComment } from '@react/Comments'
import { cls } from '@react/utils/classname'
import { useContext } from 'react'

import { DeleteAction, EditAction, LikeAction, ReplyAction } from './subcomponents'

interface CommentActionsProps {
  comment: CommentType
  likeDisabled?: boolean
  replyDisabled?: boolean
  sendActiveAction?: (action: ActionsVariants) => void
  afterSubmitting?: () => void
}
export function CommentActions({ likeDisabled = false, replyDisabled = false, comment, sendActiveAction, afterSubmitting }: CommentActionsProps) {
  const { translations, sortType } = useContext(CommentContext)

  const { likedByUser, numOfLikes, editable } = comment
  const numberOfLikes = numOfLikes || 0
  const { likeComment, setActiveAction, activeAction, onSubmit, fieldValue, setFieldValue } = useComment({ comment, afterSubmitting, sortType })
  const isFormVisible = activeAction === ActionsVariants.EDIT || activeAction === ActionsVariants.REPLY
  const fieldValueWitoutAnchorTag = fieldValue.replace(/<a[^>]*>(.*?)<\/a>/g, '$1')
  const toggleEditComment = () => {
    setActiveAction(ActionsVariants.EDIT)
    setFieldValue(comment.content)
    sendActiveAction && sendActiveAction(ActionsVariants.EDIT)
  }

  const handleCancel = () => {
    setActiveAction(null)
    setFieldValue('')
    if (sendActiveAction) {
      sendActiveAction(null)
    }
  }

  const handleSubmit = () => {
    if (activeAction === ActionsVariants.SHOW_DELETE_DIALOG) {
      sendActiveAction && sendActiveAction(ActionsVariants.DELETE_COMMENT)
    }

    onSubmit()

    if (sendActiveAction) {
      sendActiveAction(null)
    }
  }

  let submitText = activeAction === ActionsVariants.SHOW_DELETE_DIALOG && translations.deleteCommentConfirm

  if (activeAction === ActionsVariants.EDIT) {
    submitText = translations.editCommentConfirm
  } else if (activeAction === ActionsVariants.REPLY) {
    submitText = translations.reply
  }

  const likeLabel = `${numberOfLikes} ${numberOfLikes > 1 || numberOfLikes === 0 ? translations.likes : translations.like}`

  return (
    <div
      className={cls({
        'cmp-comment-actions__wrapper': true,
        'cmp-comment-actions__wrapper--edit': activeAction === ActionsVariants.EDIT || activeAction === ActionsVariants.REPLY,
        'cmp-comment-actions__wrapper--confirmation': activeAction === ActionsVariants.SHOW_DELETE_DIALOG,
      })}
    >
      {!activeAction && (
        <div className="cmp-comment-actions">
          {!replyDisabled && (
            <ReplyAction onClick={() => setActiveAction(ActionsVariants.REPLY)} classNames="cmp-comment-actions__reply" label={translations.reply} />
          )}
          {!likeDisabled && (
            <>
              {!replyDisabled && <span className="cmp-comment-actions__separator">|</span>}
              <LikeAction onClick={likeComment} classNames="cmp-comment-actions__like" label={likeLabel} isActive={likedByUser} />
            </>
          )}
          {editable && (
            <>
              {!(likeDisabled && replyDisabled) && <span className="cmp-comment-actions__separator">|</span>}
              <EditAction onClick={toggleEditComment} classNames="cmp-comment-actions__edit" label={translations.editComment} />
              <span className="cmp-comment-actions__separator">|</span>
              <DeleteAction
                onClick={() => setActiveAction(ActionsVariants.SHOW_DELETE_DIALOG)}
                label={translations.deleteComment}
                classNames="cmp-comment-actions__delete"
              />
            </>
          )}
        </div>
      )}

      <div>
        {isFormVisible && (
          <textarea
            className="cmp-comment-actions__dialog-textarea"
            placeholder={activeAction === ActionsVariants.REPLY ? translations.addReply : ''}
            value={fieldValueWitoutAnchorTag}
            onChange={e => setFieldValue && setFieldValue(e.target.value)}
            data-testid="comment-dialog-textarea"
          ></textarea>
        )}
        <div className="cmp-comment-actions__dialog-actions">
          {activeAction && (
            <CommentDialog
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              disabled={false}
              cancelText={translations.cancel}
              submitText={submitText}
            />
          )}
        </div>
      </div>
    </div>
  )
}
